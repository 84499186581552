.content {
    position: relative;
}

.message {
    border: 1px solid #b6b6b6;
    position: fixed;
    z-index: 2;
    background: #c5c6c7fa;
    width: 200px;
    text-align: center;
    font-size: 12px;
    color: black;
    top: 50vh;
    left: 50vw;
    margin-left: -100px;
}
.message h1 {
    font-size: 18px;
    color: #1c558d;
}

.message button {
    padding: 5px 20px;
    background: white;
    margin-bottom: 10px;
}