.App-logo {
    height: 75px;
    top: 0;
    left: 20px;
    position: absolute;
  }
  
  .App-header {
    height: 70px;
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: white;
    box-shadow: 0 0 5px #555;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .header-img {
    max-width: 100%;
    margin-top: 70px;
  }
  
  .imp-inf-list li {
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    margin-bottom: 9px;
  }
  .imp-inf-list li strong {
    color: #00467B;
  }
  
  
  body {
    padding: 0;
    margin: 0;
    color: #5f5f5f;
    font-family: 'Roboto',sans-serif;
    font-size: 18px;
  }
  
  h2, h3 {
    font-family: 'Averia Serif Libre', cursive;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .container {
    padding: 0 20px;
  }
  h2 {
    margin-bottom: 10px;
  }
  h3 {
    text-transform: uppercase;
    margin: 0;
    font-size: 13px;
    max-width: 245px;
    line-height: 1.425;
  }
  
  .grey-text {
    font-size: 13px;
    line-height: 1.425;
  }
  .grey-text a {
    text-decoration: none;
    font-weight: bold;
    color: #00467B;
  }
  
  footer {
    font-size: 14px;
    background: url('./assets/footer.png') top center no-repeat;
  }
  .small-info {
    font-size: 13px;
  }
  
  .footer-logo {
    width: 70px;
    margin: 20px 0 0 16px;
  }
  
  .footer-details {
    padding: 0 20px 10px;
  }
  
  .address a {
    font-weight: bold;
    text-decoration: none;
    color: #00467B;
  }
  
  .small-info a {
    text-decoration: none;
    color: #555;
  }